import { createApp } from 'vue';
import localforage from 'localforage';

import '../assets/css/tailwind.css';

import.meta.env.PROD && (console.info = console.debug = () => { });

const env = import.meta.env;
const DEFAULT_API = env.PUSHPASS_API_URL || 'http://localhost:9000';

// Sentry ------------------------------------------------------
let Sentry = null;
// @ts-expect-error __IS_ELECTRON__ a build-time constant defined by the bundler (Vite)
if (__IS_ELECTRON__) {
  console.debug('Loading Sentry/Electron/Renderer module...');
  Sentry = (await import('@sentry/electron/renderer')).default;
} else {
  console.info('Loading Sentry/Vue module...');
  Sentry = (await import('@sentry/vue')).default
}
// Prepare Sentry as soon as possible on initialization
if (!env.DEV) {
  console.debug('Initializing Sentry');
  Sentry?.init({
    dsn: env.PUSHPASS_SENTRY_DSN,
    environment: env.PUSHPASS_SENTRY_ENV || 'development',
    tracesSampleRate: env.PROD ? 0.6 : 1.0,
  });
}
// -------------------------------------------------------------

async function prepareElectronGlobals() {
  console.info('Preparing Globals for Electron Target');

  Object.defineProperty(window, 'platform', {
    value: 'electron',
    writable: false,
  });

  const serverUri = await window.localConfigs.getItem('apiServerURL') || DEFAULT_API;

  if (serverUri) {
    Object.defineProperty(window, 'apiServerURL', {
      value: serverUri,
      writable: false,
    });
  }

  // When users selects a new API from the DesktopMenu
  window.electron.on('update-api-url', (event: unknown, url: string) => {
    window.localConfigs.setItem('apiServerURL', url);
  })
  window.electron.on('clear-cache', () => {
    window.localCache.clear();
    alert('Cache Cleared');
  });
  window.electron.on('enable-cache', () => {
    window.electronStoreConfigs.set('cache.enabled', true);
  });
  window.electron.on('disable-cache', () => {
    window.electronStoreConfigs.set('cache.enabled', false);
  });

  console.debug(`[SanityCheck]: Using electron global? ${!!window.electron}`);
  console.debug(`[PushPass API] Using endpoint URL: ${window.localConfigs.getItem('apiServerURL')}`);
};

async function prepareWebGlobals() {
  console.info('Preparing Globals for Web Target');

  Object.defineProperty(window, 'platform', {
    value: 'web',
    writable: false,
  });

  const serverUri = DEFAULT_API;

  if (serverUri) {
    Object.defineProperty(window, 'apiServerURL', {
      value: serverUri,
      writable: false,
    });
  }

  console.debug(`[PushPass API] Setting endpoint URL: ${window.apiServerURL}`);
};

const init = async () => {
  console.debug('Running as production? ' + import.meta.env.PROD);
  console.debug('Using Environment', env);
  const configStore = localforage.createInstance({ name: 'configs' })

  Object.defineProperty(window, 'localConfigs', {
    value: configStore,
    writable: false,
  });

  Object.defineProperty(window, 'cacheKey', {
    value: DEFAULT_API.replace(/[^a-zA-Z ]/g, ''),
    writable: false,
  });

  Object.defineProperty(window, 'localCache', {
    value: localforage.createInstance({ name: 'app-local-cache' }),
    writable: false,
  });

  if (window?.electron) await prepareElectronGlobals();
  else await prepareWebGlobals();

  /****************************************************************************
   * WARNING DO NOT CHANGE DYNAMIC IMPORT
   *  Dynamic Imports allow us to load all global variables first
   *  and then mount the router. This is important for Auth which preloads
   *  with the API Url. See {@link packages/web-admin/src/api/fetch/client.ts}
  ****************************************************************************/
  const AppComponent = (await import('./App.vue')).default;
  const app = createApp(AppComponent);
  const router = (await import('./router')).default;

  // Mount Router
  app.use(router).mount('#app');
};

// Initialize the app
console.groupCollapsed('Init App');
init();
console.groupEnd();
